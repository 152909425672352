import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"
import ActionButton from "../components/actionButton"

const Termine = ({ data }) => {
  // Single Image Data
  const images = {
    image1: data.image1.childImageSharp.fluid,
    image2: data.image2.childImageSharp.fluid,
  }

  return (
    <Layout
      title="Stellenangebote"
      slogan="Offene Stellen und Lehrbetrieb"
      headerImage={data.image1.childImageSharp.fluid}
    >
      <h4>Offene Stellen</h4>
      <p style={{ marginBottom: "20px" }}>
        Wir sind jederzeit für ein Gespräch offen.{" "}
        <Link to="/kontakt/">Kontaktieren</Link> Sie uns, wir freuen uns Sie
        kennenzulernen.
      </p>
      {/* <li className="formular-download-link formular-download-link--disabled" style={{fontSize: '1.2em'}}>Derzeit sind keine Stellen ausgeschrieben</li> */}

      {/* <ul style={{margin: '0'}}> 
          <ActionButton type="icon-pdf" address="/download/Pflege-Stationsleiter-in.pdf" text="Pflegefachfrau / Pflegefachmann als Stationsleiter/in 60-70%" />
        </ul>
        <ul style={{margin: '0'}}> 
          <ActionButton type="icon-pdf" address="/download/Inserat-Raumpflegerin.pdf" text="Raumpflegerin" />
        </ul> */}
      {/* <ul style={{margin: '0'}}> 
          <ActionButton type="icon-pdf" address="/download/Stelle-Pflege-Fachfrau-2021.pdf" text="Pflegefachfrau / Pflegefachmann mit Zusatzfunktion Stv. Leitung Pflege & Betreuung" />
        </ul> */}
      <ul style={{ margin: "0" }}>
        <ActionButton
          type="icon-pdf"
          address="/download/Lehrstelle AGS 2025.pdf"
          text="Assistent/Assistentin Gesundheit uns Soziales ab August 2025"
        />
      </ul>
      <ul style={{ margin: "0" }}>
        <ActionButton
          type="icon-pdf"
          address="/download/Praktikantin 2024.pdf"
          text="Praktikum Brückenangebot"
        />
      </ul>

      <div style={{ marginTop: "80px" }}>
        <div className="lehrbetrieb-row">
          <Image
            fluid={images.image2}
            style={{ width: "100px", margin: "auto" }}
          />
        </div>
        <div>
          <h4>Lehrbetrieb</h4>
          <p>
            Wir bieten die Möglichkeit die Ausbildung{" "}
            <b>Assistent/in Gesundheit und Soziales EBA</b> zu absolvieren.
          </p>
          <br />
        </div>
      </div>
    </Layout>
  )
}

export default Termine

export const pageQuery = graphql`
  query {
    image1: file(
      sourceInstanceName: { eq: "assets" }
      relativePath: { eq: "bannau-start-eingang.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(
      sourceInstanceName: { eq: "assets" }
      relativePath: { eq: "bannau-lehrbetrieb.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
